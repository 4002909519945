<template>
  <div>
    <b-overlay :show="tableLoading" rounded="sm">
    <b-row>
      <b-col cols="12"  md="3">
        <span class="heading">
          Welcome back, {{user.first_name}} 👋
          </span>
      </b-col>
      <b-col cols="12" md="7">
        <b-row class="mt-n0 ml-0 mr-0 float-right">
            <div class="row">
                <div class="d-flex align-items-center mr-3 mb-1">

                    <b-avatar variant="light-primary" rounded>
                        <feather-icon icon="UsersIcon" size="18"/>
                    </b-avatar>

                   <div class="ml-1">
                     <h4 class="mb-0 font-weight-bolder ">{{summery.staff }} </h4>
                     <small>Total Staff</small>
                    </div>
                </div>

                <div class="d-flex align-items-center mr-3 mb-1">

                  <b-avatar variant="light-primary" rounded>
                      <feather-icon icon="CoffeeIcon" size="18" />
                  </b-avatar>

                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder"> {{summery.todayCount}} </h4>
                    <small>On leave today </small>
                  </div>
                </div>
                
                <div class="d-flex align-items-center mb-1">

                  <b-avatar variant="light-success" rounded>
                      <feather-icon icon="CoffeeIcon" size="18" />
                  </b-avatar>

                  <div class="ml-1">
                    <h4 class="mb-0 font-weight-bolder"> {{ leaveRolling}} </h4>
                    <small>On leave over the next 4 weeks</small>
                  </div>
                </div>
            </div>
        </b-row>
      </b-col>
      <b-col cols="12"  md="2">
        <div class="flex flex-wrap float-right mb-2 mb-md-0">

          <b-button
              variant="outline-white"
              class="btn-icon shadow-sm bg-white text-primary mr-1"
              @click="refresh()"
          >
            <feather-icon icon="RefreshCwIcon"  size="18"/>
          </b-button>
          <b-button
              variant="outline-white"
              class="btn-icon shadow-sm bg-white text-primary"
              @click="toggleSideBars()"
          >
            <feather-icon icon="SettingsIcon"  size="18"/>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- <div class="mt-3">
       <span class="overview font-weight-bolder" style="font-size: 15px;">
        Your weekly overview
       </span>
    </div>

    <b-row>
      <b-col cols="4" lg="4" md="12" class="pt-0 pb-0">
        <div class="row flex flex-wrap" style="margin-left: 5px;">
          <div class="d-flex align-items-center mr-5 mt-2">
            <ul class="list-inline mb-0">
              <b-avatar variant="white" class="btn-icon text-primary">
                <feather-icon icon="CalendarIcon" size="27"/>
              </b-avatar>

              <div class="">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <h4 class="mt-1 summery-heading">Scheduled shifts</h4>
                  </li>
                </ul>
                <span class="summery font-weight-700">{{summery.scheduled_shifts}}</span>
              </div>
            </ul>
          </div>

          <div class="d-flex align-items-center mr-5 ml-1 mt-2">
            <ul class="list-inline mb-0">
              <b-avatar variant="white" class="btn-icon text-primary">
                <feather-icon icon="UsersIcon" size="27"/>
              </b-avatar>

              <div class="">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <h4 class="mt-1 summery-heading">Total staff</h4>
                  </li>
                </ul>
                <span class="summery font-weight-700">{{summery.staff}}</span>
              </div>
            </ul>
          </div>
          <div class="d-flex align-items-center mr-5 ml-1 mt-2">
            <ul class="list-inline mb-0">
              <b-avatar variant="white" class="btn-icon text-primary">
                <feather-icon icon="LinkIcon" size="27"/>
              </b-avatar>

              <div class="">
                <ul class="list-inline mb-0">
                  <li class="list-inline-item">
                    <h4 class="mt-1 summery-heading">On leave today</h4>
                  </li>
                </ul>
                <span class="summery font-weight-700">{{summery.todayCount}}</span>
              </div>
            </ul>
          </div>
        </div>
      </b-col>
      <b-col cols="8" lg="8" md="12" class="pt-0 pb-0">
        <div class="d-flex  align-items-center box " style="width:99%;">
          <b-row>
            <b-col class=" mr-2 ml-2">
              <svg width="190" height="181" viewBox="0 0 190 181" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.1" d="M96.4805 156.203C136.276 156.203 168.538 155.254 168.538 154.083C168.538 152.912 136.276 151.963 96.4805 151.963C56.6847 151.963 24.4238 152.912 24.4238 154.083C24.4238 155.254 56.6847 156.203 96.4805 156.203Z" fill="#CCE6F4"/>
                <path d="M106.327 153.225H57.4027C53.4344 153.225 50.7629 150.008 51.4357 146.04L71.1399 29.834C71.8127 25.8655 75.5753 22.6484 79.5436 22.6484H128.468C132.436 22.6484 135.108 25.8655 134.435 29.834L114.731 146.04C114.058 150.008 110.295 153.225 106.327 153.225Z" fill="#5CA4E6"/>
                <path d="M100.216 153.225H51.2918C47.3236 153.225 44.6521 150.008 45.3249 146.04L65.029 29.834C65.7019 25.8655 69.4643 22.6484 73.4328 22.6484H122.357C126.325 22.6484 128.997 25.8655 128.324 29.834L108.62 146.04C107.947 150.008 104.184 153.225 100.216 153.225Z" fill="#D1E8F7"/>
                <path d="M100.498 149.34H52.3284C50.2678 149.34 48.8807 147.67 49.2301 145.609L68.7883 30.2641C69.1377 28.2036 71.0913 26.5332 73.1519 26.5332H121.321C123.382 26.5332 124.769 28.2036 124.419 30.2641L104.861 145.609C104.512 147.67 102.558 149.34 100.498 149.34Z" fill="white"/>
                <path d="M105.057 65.3961H58.999L63.7993 38.8769C64.1138 37.26 65.6796 35.9492 67.2964 35.9492H110.784C112.401 35.9492 113.457 37.26 113.142 38.8769L108.554 62.4685C108.239 64.0853 106.674 65.3961 105.057 65.3961Z" fill="url(#paint0_linear_341_1819)"/>
                <path d="M101.344 61.9822H32.3931C30.7764 61.9822 29.7204 60.6714 30.0349 59.0545L34.6232 35.4629C34.9377 33.8459 36.5033 32.5352 38.1203 32.5352H107.072C108.688 32.5352 109.744 33.8459 109.43 35.4629L104.842 59.0545C104.527 60.6714 102.961 61.9822 101.344 61.9822Z" fill="#00C49A"/>
                <path d="M96.9454 146.332L54.1803 146.535C52.5633 146.535 51.5075 145.224 51.822 143.607L56.4104 120.016C56.7249 118.399 58.2904 117.088 59.9074 117.088L102.673 116.885C104.29 116.885 105.345 118.196 105.031 119.813L100.442 143.404C100.128 145.021 98.5624 146.332 96.9454 146.332Z" fill="#00C49A"/>
                <path d="M58.8838 139.188C60.1006 129.671 69.1436 121.955 79.0821 121.955C89.0205 121.955 96.0908 129.671 94.874 139.188" stroke="#09A684" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M58.8838 139.188C60.1006 129.671 69.1436 121.955 79.0821 121.955C84.001 121.955 88.2173 123.845 91.074 126.908" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M91.8315 113.552H61.2105C59.5761 113.552 58.4714 112.227 58.7433 110.593L65.2103 71.6936C65.4819 70.0592 67.0272 68.7344 68.6616 68.7344H99.2824C100.917 68.7344 102.022 70.0592 101.75 71.6936L95.2827 110.593C95.011 112.227 93.4659 113.552 91.8315 113.552Z" fill="url(#paint1_linear_341_1819)"/>
                <path d="M70.6395 89.3031H66.4645C66.2349 89.311 66.0146 89.3967 65.8402 89.5461C65.6658 89.6958 65.5474 89.9004 65.5048 90.1262L63.2373 103.765C63.2152 103.866 63.2166 103.971 63.2412 104.071C63.2659 104.172 63.3131 104.265 63.3792 104.344C63.4454 104.424 63.5289 104.487 63.6231 104.529C63.7175 104.572 63.8202 104.592 63.9234 104.588H68.0983C68.3282 104.581 68.5483 104.495 68.7227 104.345C68.8973 104.196 69.0154 103.991 69.0583 103.765L71.3257 90.1262C71.3477 90.0253 71.3462 89.9205 71.3217 89.8202C71.2971 89.7197 71.2498 89.6263 71.1836 89.547C71.1176 89.4676 71.0342 89.4044 70.9398 89.3622C70.8456 89.32 70.7428 89.2997 70.6395 89.3031Z" fill="white"/>
                <path d="M78.2568 93.9691H74.0818C73.852 93.9768 73.6319 94.0625 73.4575 94.2122C73.2829 94.3618 73.1647 94.5662 73.1219 94.792L71.6302 103.765C71.6083 103.866 71.6097 103.97 71.6343 104.071C71.6588 104.171 71.7061 104.264 71.7723 104.344C71.8385 104.423 71.9218 104.486 72.0162 104.529C72.1104 104.571 72.2131 104.591 72.3165 104.588H76.4914C76.7211 104.58 76.9413 104.494 77.1157 104.345C77.2902 104.195 77.4085 103.99 77.4512 103.765L78.9428 94.792C78.965 94.6911 78.9635 94.5863 78.939 94.486C78.9142 94.3857 78.8671 94.2924 78.8009 94.213C78.7347 94.1336 78.6513 94.0702 78.5571 94.028C78.4627 93.9858 78.3599 93.9657 78.2568 93.9691Z" fill="white"/>
                <path d="M88.3403 80.8383H84.1654C83.9358 80.846 83.7157 80.9317 83.5411 81.0813C83.3667 81.2309 83.2483 81.4354 83.2057 81.6612L79.5312 103.764C79.5092 103.865 79.5105 103.969 79.5352 104.07C79.5598 104.17 79.6069 104.263 79.6731 104.343C79.7393 104.422 79.8227 104.485 79.9169 104.528C80.0113 104.57 80.1141 104.59 80.2172 104.587H84.3922C84.622 104.579 84.8421 104.493 85.0165 104.344C85.1911 104.194 85.3093 103.989 85.3521 103.764L89.0266 81.6612C89.0485 81.5603 89.0471 81.4555 89.0225 81.3552C88.998 81.2549 88.9509 81.1615 88.8847 81.0821C88.8185 81.0027 88.735 80.9394 88.6406 80.8972C88.5464 80.8549 88.4437 80.8348 88.3403 80.8383Z" fill="white"/>
                <path d="M95.5251 86.5687H91.3502C91.1205 86.5765 90.9002 86.6621 90.7258 86.8118C90.5514 86.9614 90.4331 87.1659 90.3904 87.3916L87.6684 103.765C87.6464 103.866 87.6477 103.97 87.6724 104.071C87.697 104.171 87.7441 104.264 87.8103 104.344C87.8765 104.423 87.9599 104.487 88.0541 104.529C88.1485 104.571 88.2513 104.591 88.3544 104.588H92.5294C92.7592 104.58 92.9793 104.494 93.1539 104.345C93.3283 104.195 93.4467 103.991 93.4893 103.765L96.2114 87.3916C96.2333 87.2907 96.2319 87.186 96.2073 87.0857C96.1827 86.9854 96.1354 86.892 96.0693 86.8126C96.0031 86.7332 95.9198 86.6699 95.8254 86.6276C95.7312 86.5854 95.6285 86.5653 95.5251 86.5687Z" fill="white"/>
                <path d="M68.2656 73.5156H79.5693" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M67.6279 77.3535H73.2797" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M64.4556 107.369H66.6418" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M72.8491 107.369H75.0352" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M80.7495 107.369H82.9358" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M88.8867 107.369H91.073" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M131.997 113.552H101.377C99.7423 113.552 98.6376 112.227 98.9092 110.593L105.376 71.6936C105.648 70.0592 107.193 68.7344 108.828 68.7344H139.449C141.083 68.7344 142.188 70.0592 141.916 71.6936L135.449 110.593C135.177 112.227 133.632 113.552 131.997 113.552Z" fill="url(#paint2_linear_341_1819)"/>
                <path d="M110.012 95.1664H105.837C105.608 95.1741 105.387 95.2598 105.213 95.4094C105.039 95.5591 104.92 95.7635 104.878 95.9893L103.403 103.764C103.381 103.865 103.383 103.97 103.407 104.07C103.432 104.171 103.479 104.264 103.545 104.343C103.611 104.423 103.695 104.486 103.789 104.528C103.883 104.571 103.986 104.591 104.089 104.587H108.264C108.494 104.58 108.714 104.494 108.889 104.344C109.063 104.195 109.181 103.99 109.224 103.764L110.699 95.9893C110.72 95.8884 110.719 95.7836 110.694 95.6833C110.67 95.583 110.623 95.4896 110.556 95.4102C110.49 95.3308 110.407 95.2675 110.313 95.2253C110.218 95.183 110.116 95.1629 110.012 95.1664Z" fill="white"/>
                <path d="M120 83.1273H115.825C115.595 83.135 115.375 83.2207 115.201 83.3704C115.026 83.52 114.908 83.7244 114.865 83.9502L111.797 103.765C111.775 103.866 111.776 103.971 111.801 104.071C111.825 104.171 111.872 104.265 111.939 104.344C112.005 104.423 112.088 104.487 112.183 104.529C112.277 104.571 112.38 104.591 112.483 104.588H116.658C116.888 104.58 117.108 104.494 117.282 104.345C117.457 104.195 117.575 103.991 117.618 103.765L120.686 83.9502C120.708 83.8493 120.707 83.7446 120.682 83.6443C120.657 83.544 120.61 83.4506 120.544 83.3712C120.478 83.2918 120.395 83.2284 120.3 83.1862C120.206 83.144 120.103 83.1239 120 83.1273Z" fill="white"/>
                <path d="M127.125 88.7816H122.95C122.72 88.7893 122.5 88.875 122.326 89.0247C122.151 89.1743 122.033 89.3788 121.99 89.6045L119.697 103.762C119.675 103.863 119.677 103.967 119.701 104.068C119.726 104.168 119.773 104.261 119.839 104.341C119.905 104.42 119.989 104.484 120.083 104.526C120.177 104.568 120.28 104.588 120.383 104.585H124.558C124.788 104.577 125.008 104.491 125.183 104.342C125.357 104.192 125.475 103.988 125.518 103.762L127.811 89.6045C127.833 89.5036 127.832 89.3988 127.807 89.2985C127.783 89.1983 127.736 89.1049 127.669 89.0255C127.603 88.9461 127.52 88.8827 127.425 88.8405C127.331 88.7983 127.228 88.7782 127.125 88.7816Z" fill="white"/>
                <path d="M135.691 86.5687H131.516C131.286 86.5765 131.066 86.6621 130.892 86.8118C130.717 86.9614 130.599 87.1659 130.556 87.3916L127.834 103.765C127.812 103.866 127.814 103.97 127.838 104.071C127.863 104.171 127.91 104.264 127.976 104.344C128.043 104.423 128.126 104.487 128.22 104.529C128.315 104.571 128.417 104.591 128.521 104.588H132.696C132.925 104.58 133.145 104.494 133.32 104.345C133.494 104.195 133.613 103.991 133.655 103.765L136.377 87.3916C136.399 87.2907 136.398 87.186 136.373 87.0857C136.349 86.9854 136.302 86.892 136.235 86.8126C136.169 86.7332 136.086 86.6699 135.992 86.6276C135.897 86.5854 135.794 86.5653 135.691 86.5687Z" fill="white"/>
                <path d="M108.432 73.5156H119.736" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M107.794 77.3535H113.446" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M104.622 107.369H106.808" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M113.015 107.369H115.201" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M120.916 107.369H123.102" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M129.053 107.369H131.239" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M2.02155 157.268L10.7166 175.457L9.63833 175.973C9.27429 176.147 8.85625 176.169 8.47597 176.035C8.09569 175.901 7.78423 175.621 7.61044 175.257L0.226746 159.811C0.0529502 159.447 0.0306181 159.029 0.164823 158.649C0.29923 158.269 0.579011 157.957 0.943049 157.783L2.02155 157.268Z" fill="#71C8EA"/>
                <path d="M11.4877 153.16L5.48682 155.641L8.8533 160.402L14.1732 158.778L11.4877 153.16Z" fill="#FFCCB3"/>
                <path d="M7.65493 155.831C7.65493 155.831 9.99772 159.449 10.3512 161.471C10.7047 163.493 11.915 173.721 11.5893 174.643C11.3552 175.305 10.5049 175.59 9.96544 175.65C9.84707 175.663 9.72769 175.638 9.62394 175.58C9.52019 175.521 9.43695 175.432 9.38619 175.325L1.49329 158.813C1.36132 158.538 1.34284 158.221 1.44152 157.933C1.54039 157.643 1.7485 157.405 2.02158 157.268C3.11349 156.719 4.78384 155.897 5.48715 155.641C6.57418 155.245 7.2917 155.248 7.65493 155.831Z" fill="#4361EE"/>
                <path d="M50.1348 164.486L41.9804 179.539C41.7881 179.894 41.463 180.158 41.0765 180.273C40.6899 180.387 40.2735 180.344 39.9188 180.152L38.8677 179.583L48.4711 161.855L49.522 162.425C49.8767 162.617 50.1405 162.942 50.2554 163.329C50.3703 163.715 50.327 164.132 50.1348 164.486Z" fill="#71C8EA"/>
                <path d="M39.2239 157.273L45.0918 160.054L41.4889 164.639L36.2578 162.749L39.2239 157.273Z" fill="#FFCCB3"/>
                <path d="M42.9175 160.135C42.9175 160.135 40.3948 163.631 39.9396 165.632C39.4846 167.634 37.759 177.787 38.0376 178.725C38.2378 179.398 39.0726 179.725 39.6084 179.812C39.726 179.831 39.8464 179.813 39.953 179.76C40.0596 179.707 40.1471 179.622 40.2033 179.517L48.9207 163.425C49.0663 163.157 49.1008 162.842 49.0168 162.548C48.9327 162.255 48.7368 162.006 48.4712 161.855C47.4083 161.252 45.7814 160.346 45.0921 160.055C44.0264 159.605 43.3097 159.571 42.9175 160.135Z" fill="#4361EE"/>
                <path d="M56.9017 135.836C56.9017 135.836 59.148 146.991 57.4048 149.177L12.9843 160.551L9.19629 153.456L35.136 141.685L40.331 134.564L56.9017 135.836Z" fill="#B1D7EF"/>
                <path d="M45.6613 143.791L35.8605 147.069L31.2872 148.987L37.2344 154.337L31.9174 155.538L17.4146 143.791H45.6613Z" fill="#5CA4E6"/>
                <path d="M11.98 152.521L12.2815 153.569L19.1684 158.945L25.6376 157.366C25.6376 157.366 13.1578 151.427 11.98 152.521Z" fill="#5CA4E6"/>
                <path d="M24.4475 135.181C24.4475 135.181 11.8869 134.499 5.6611 135.047C1.65221 135.399 0.20703 145.658 3.40622 147.524L37.7403 165.104L41.7863 157.793L19.6377 142.8L35.4834 140.572L24.4475 135.181Z" fill="#B1D7EF"/>
                <path d="M6.71673 148.398C10.4261 148.398 13.4333 145.391 13.4333 141.682C13.4333 137.972 10.4261 134.965 6.71673 134.965C3.00712 134.965 0 137.972 0 141.682C0 145.391 3.00712 148.398 6.71673 148.398Z" fill="#B1D7EF"/>
                <path d="M56.289 149.265C59.9984 149.265 63.0055 146.258 63.0055 142.549C63.0055 138.839 59.9984 135.832 56.289 135.832C52.5794 135.832 49.5723 138.839 49.5723 142.549C49.5723 146.258 52.5794 149.265 56.289 149.265Z" fill="#B1D7EF"/>
                <path d="M37.8899 110.855C37.1823 107.362 38.8464 107.452 39.4003 106.897C39.4003 106.897 41.4777 105.184 43.0608 106.78C44.7364 108.47 54.3417 121.592 54.3417 121.592L48.3317 127.119L47.202 125.771L44.2757 122.281L41.1457 118.551L40.7569 118.126L36.5347 113.344C36.5347 113.344 38.2901 112.694 37.8899 110.855Z" fill="#1B9AAA"/>
                <path d="M41.223 114.363C43.642 113.553 44.9458 110.935 44.1357 108.516C43.3256 106.098 40.7081 104.794 38.2892 105.604C35.8705 106.414 34.5664 109.031 35.3767 111.45C36.1868 113.869 38.8043 115.173 41.223 114.363Z" fill="#1B9AAA"/>
                <path d="M40.8123 118.107C40.6911 117.377 40.4487 115.917 40.1878 114.586L38.8013 108.145C38.8013 108.145 42.5253 113.649 44.2411 117.483C45.5117 120.356 46.9857 123.284 47.2296 125.761L44.3035 122.271L41.1735 118.541L40.8123 118.107Z" fill="#0E8695"/>
                <path d="M60.3447 106.818C61.7729 104.213 61.0866 103.771 61.6941 102.289C62.2926 100.843 61.23 97.2579 62.2474 97.7468C63.2645 98.2355 63.5794 99.5347 63.5794 99.5347C64.2752 98.3037 64.916 96.0391 66.5815 95.7798C68.2474 95.5207 68.3948 96.045 69.3389 96.8197C71.8506 98.9095 68.6478 102.05 68.1132 103.246C67.7692 103.996 65.2421 106.281 64.9621 106.781L63.6901 108.626L60.3447 106.818Z" fill="#FFCCB3"/>
                <path d="M65.252 99.6966L67.2817 95.9492L65.252 99.6966Z" fill="#E56441"/>
                <path d="M65.252 99.6966L67.2817 95.9492" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M66.582 100.301L68.7943 96.8105L66.582 100.301Z" fill="#E56441"/>
                <path d="M66.582 100.301L68.7943 96.8105" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M68.1616 100.975L69.4259 98.8613L68.1616 100.975Z" fill="#E56441"/>
                <path d="M68.1616 100.975L69.4259 98.8613" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M64.7503 102.995C64.7396 101.697 63.9609 100.127 63.9609 100.127L64.7503 102.995Z" fill="#E56441"/>
                <path d="M64.7503 102.995C64.7396 101.697 63.9609 100.127 63.9609 100.127" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M48.2808 121.486L60.5019 105.211C60.5797 105.116 60.6903 105.052 60.8121 105.033C60.934 105.014 61.0586 105.041 61.1618 105.109L64.362 107.225C64.514 107.328 64.6223 107.484 64.6659 107.662C64.7096 107.841 64.6852 108.029 64.5977 108.191L54.7697 126.315L48.2808 121.486Z" fill="#1B9AAA"/>
                <path d="M51.2502 128.359C53.5106 128.359 55.343 126.527 55.343 124.266C55.343 122.006 53.5106 120.174 51.2502 120.174C48.9901 120.174 47.1577 122.006 47.1577 124.266C47.1577 126.527 48.9901 128.359 51.2502 128.359Z" fill="#1B9AAA"/>
                <path d="M33.1019 103.297C33.1019 103.297 39.9601 105.112 41.1373 105.583C42.3147 106.054 42.3931 107.937 42.4716 109.272C42.55 110.606 42.2865 140.571 42.2865 140.571L27.1086 139.987L15.7578 133.343C15.7578 133.343 16.2514 109.567 16.4083 108.468C16.5653 107.369 15.3318 106.423 16.6688 105.87L25.2632 103.599L33.1019 103.297Z" fill="#1B9AAA"/>
                <path d="M21.6807 113.976L21.8029 136.116L15.2778 128.843L18.4348 113.287L21.6807 113.976Z" fill="#0E8695"/>
                <path d="M24.1439 103.938C24.1439 103.938 23.173 105.89 24.1439 107.237C25.1148 108.584 29.0017 109.893 31.6571 109.854C34.3124 109.816 35.7194 107.284 34.5156 103.739L30.1177 102.504L25.2632 103.599L24.1439 103.938Z" fill="#0E8695"/>
                <path d="M35.1366 87.1094C35.1366 87.1094 37.0487 91.2356 35.0093 92.0879C32.9698 92.9403 33.2074 87.3096 35.1366 87.1094Z" fill="#93523E"/>
                <path d="M32.9795 100.314L33.5327 106.552C33.5327 106.552 34.0631 107.389 31.9976 107.828C30.669 108.11 27.0911 106.701 26.3969 106.278C26.1017 106.121 25.8705 105.866 25.743 105.558C25.6155 105.249 25.5996 104.905 25.6981 104.586C25.9091 103.822 26.7833 97.4629 26.7833 97.4629L32.9795 100.314Z" fill="#FFCCB3"/>
                <path d="M33.0862 101.517L29.7905 99.8887L33.4646 105.783L33.0862 101.517Z" fill="#FF967C"/>
                <path d="M34.0384 88.3051C34.0384 88.3051 35.9725 93.1558 36.674 96.5584C37.3818 99.9915 36.2525 100.849 34.7624 101.354C33.5871 101.752 31.7102 102.07 30.4336 101.67C28.1054 100.941 25.8278 98.8372 25.283 98.4052C24.4396 97.7362 23.607 94.7017 24.8431 92.985C26.0793 91.2682 30.9499 85.8732 34.0384 88.3051Z" fill="#FFCCB3"/>
                <path d="M31.5481 94.9999C31.8054 94.8986 31.8886 94.4984 31.7341 94.1058C31.5796 93.7131 31.246 93.477 30.9888 93.5781C30.7315 93.6794 30.6483 94.0796 30.8026 94.4722C30.9571 94.8649 31.2909 95.101 31.5481 94.9999Z" fill="#080B09"/>
                <path d="M35.0832 93.5837C35.3405 93.4826 35.4237 93.0822 35.2692 92.6897C35.1149 92.2971 34.7811 92.0609 34.5239 92.1621C34.2666 92.2634 34.1834 92.6635 34.3379 93.0562C34.4922 93.4487 34.826 93.685 35.0832 93.5837Z" fill="#080B09"/>
                <path d="M33.7871 94.252C33.7871 94.252 33.473 95.2026 34.1332 95.11C34.7608 94.9999 35.1868 95.3099 34.6384 95.9469" fill="#E56441"/>
                <path d="M33.7871 94.252C33.7871 94.252 33.473 95.2026 34.1332 95.11C34.7608 94.9999 35.1868 95.3099 34.6384 95.9469" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M34.6689 96.7872L34.6722 96.7866L34.6754 96.7855C34.6845 96.7827 34.6943 96.7825 34.7034 96.7847C34.7128 96.7867 34.7213 96.7914 34.7282 96.7981C34.7351 96.8048 34.7402 96.8131 34.7428 96.8223C34.7453 96.8316 34.7455 96.8414 34.743 96.8505L34.741 96.8584L34.7402 96.8665C34.6998 97.2826 34.5962 97.5298 34.4706 97.6687C34.3491 97.8031 34.1969 97.8478 34.0241 97.8346C33.8468 97.821 33.6521 97.7463 33.4635 97.6438C33.2771 97.5424 33.1039 97.418 32.9701 97.3112C32.961 97.3027 32.9543 97.2921 32.9506 97.2801C32.9466 97.2677 32.9462 97.2543 32.9494 97.2415C32.9525 97.229 32.9592 97.2172 32.9683 97.208C32.9777 97.1987 32.9892 97.1922 33.002 97.1892L33.003 97.189C33.5135 97.0596 34.2834 96.8781 34.6689 96.7872Z" fill="#E56441" stroke="#E56441" stroke-width="0.5"/>
                <path d="M29.3087 93.3401L29.357 93.4079C29.4189 93.5129 29.5198 93.5892 29.6378 93.6201C29.7556 93.6509 29.881 93.6339 29.9864 93.5728L31.7705 92.4735C31.859 92.424 31.9256 92.3428 31.9572 92.2463C31.9887 92.1499 31.983 92.0451 31.941 91.9528C31.9179 91.8949 31.8829 91.8427 31.8381 91.7995C31.7934 91.7562 31.74 91.7229 31.6813 91.7018C31.6228 91.6807 31.5605 91.6724 31.4984 91.677C31.4363 91.6819 31.376 91.6998 31.3213 91.7296L29.489 92.761C29.4384 92.7866 29.3935 92.8219 29.3568 92.865C29.3203 92.9082 29.2926 92.9582 29.276 93.0124C29.2591 93.0664 29.2533 93.1232 29.2589 93.1795C29.2646 93.2357 29.2815 93.2903 29.3087 93.3401Z" fill="#080B09"/>
                <path d="M35.4715 90.5198L33.3778 90.7137C33.3159 90.7202 33.256 90.7399 33.2022 90.7711C33.1484 90.8024 33.1019 90.8448 33.0657 90.8956C33.0296 90.9461 33.0046 91.0038 32.9925 91.0649C32.9803 91.126 32.9811 91.189 32.9949 91.2495C33.013 91.3494 33.0678 91.4389 33.1486 91.5002C33.2296 91.5617 33.3305 91.5904 33.4316 91.5808L35.5241 91.4702C35.6455 91.4604 35.7584 91.4032 35.8378 91.3108C35.9171 91.2182 35.9567 91.0982 35.9478 90.9766L35.9492 90.8934C35.9433 90.8369 35.9261 90.7825 35.8987 90.733C35.8713 90.6834 35.8341 90.64 35.7896 90.605C35.745 90.5701 35.6938 90.5447 35.6392 90.5299C35.5846 90.5153 35.5275 90.5119 35.4715 90.5198Z" fill="#080B09"/>
                <path d="M31.6378 89.2741C31.6378 89.2741 30.5928 91.2626 28.6968 91.8522C26.8009 92.442 27.837 95.4343 27.837 95.4343C27.837 95.4343 26.537 94.141 25.0426 94.8691C23.5481 95.597 24.6825 98.454 26.7471 98.4904L26.1855 101.705C26.1855 101.705 25.1399 100.403 24.0403 99.7063C22.9404 99.0095 18.99 96.3049 20.7351 92.7427C20.7351 92.7427 19.3697 87.741 23.1144 85.1477C26.8592 82.5545 30.1004 83.5332 30.1004 83.5332C30.1004 83.5332 30.9931 82.117 33.0624 82.4061C35.1317 82.6954 35.6089 86.4268 34.9236 87.7763C34.2384 89.1259 33.1217 89.6995 31.6378 89.2741Z" fill="#93523E"/>
                <path d="M16.0362 109.213C18.6698 106.811 19.4474 108.284 20.2086 108.474C20.2086 108.474 22.7457 109.376 22.1889 111.555C21.6006 113.861 15.2777 128.843 15.2777 128.843L7.44775 126.527L8.02396 124.865L9.51463 120.561L11.1068 115.96L11.2713 115.408L13.2049 109.328C13.2049 109.328 14.6641 110.501 16.0362 109.213Z" fill="#1B9AAA"/>
                <path d="M21.2515 113.365C22.9105 111.427 22.6843 108.511 20.7466 106.852C18.8088 105.193 15.8931 105.42 14.2343 107.357C12.5753 109.295 12.8013 112.211 14.7392 113.87C16.677 115.529 19.5925 115.303 21.2515 113.365Z" fill="#1B9AAA"/>
                <path d="M14.5027 125.16L29.0394 139.405C29.1241 139.495 29.1724 139.613 29.1752 139.736C29.1781 139.859 29.1353 139.979 29.0549 140.073L26.5383 142.968C26.4162 143.106 26.2475 143.193 26.0648 143.213C25.8823 143.233 25.6987 143.184 25.5501 143.076L8.8667 130.962L14.5027 125.16Z" fill="#1B9AAA"/>
                <path d="M11.3562 131.832C13.6166 131.832 15.4489 129.999 15.4489 127.739C15.4489 125.479 13.6166 123.646 11.3562 123.646C9.09604 123.646 7.26367 125.479 7.26367 127.739C7.26367 129.999 9.09604 131.832 11.3562 131.832Z" fill="#1B9AAA"/>
                <path d="M35.0067 122.416L27.1174 144.273H11.2549C11.2549 143.606 11.52 142.966 11.9919 142.494C12.4639 142.022 13.1039 141.757 13.7713 141.757H25.2473L32.0596 122.416H35.0067Z" fill="white"/>
                <path d="M35.0064 122.416H56.2891L48.0582 144.273H27.1172L35.0064 122.416Z" fill="url(#paint3_linear_341_1819)"/>
                <path d="M64.7241 51.1348C64.7241 51.1348 68.2703 50.3879 69.3951 52.1124C70.5199 53.837 71.6447 57.0768 74.1942 56.6567C76.7437 56.2366 78.6932 47.7633 81.2427 47.8384C83.7921 47.9133 82.6673 53.4622 85.4418 53.5371C88.2162 53.612 87.5414 50.4628 89.791 50.4628C92.0406 50.4628 91.2905 57.2114 94.215 57.2863C97.1395 57.3614 96.8394 48.5882 101.264 48.738" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M54.3725 49.9702C56.1785 45.5341 54.4397 40.6341 50.4889 39.0257C46.5381 37.4173 41.8714 39.7095 40.0654 44.1456C38.2595 48.5817 39.9982 53.4817 43.949 55.0901C47.8998 56.6985 52.5668 54.4063 54.3725 49.9702Z" stroke="#09A684" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M50.8976 54.2627C48.8131 55.6377 46.2587 56.0303 43.949 55.0901C39.9982 53.4817 38.2595 48.5817 40.0654 44.1456C41.8714 39.7095 46.5381 37.4173 50.4889 39.0257" stroke="white" stroke-width="5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M67.3896 38.5117H82.9939" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.6" d="M66.6504 42.8164H72.999" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M185.527 145.493C185.483 145.498 185.462 145.523 185.487 145.543C185.466 145.568 185.466 145.568 185.472 145.613C185.377 145.923 185.31 146.24 185.271 146.561L189.121 149.655L189.547 149.176C189.547 149.176 188.126 147.419 187.594 146.907C187.365 146.682 187.16 146.477 186.955 146.271C186.776 146.086 186.616 145.876 186.461 145.71C186.246 145.415 185.623 145.528 185.617 145.483C185.592 145.464 185.527 145.493 185.527 145.493Z" fill="#233B62"/>
                <path d="M156.418 118.611L160.136 122.214L180.92 142.286L181.3 142.631L181.464 142.887L181.489 142.907L184.433 147.367L184.762 147.878L184.871 148.048L186.863 151.087L185.222 151.903L184.747 152.137L184.077 152.46L183.019 153.008L183.001 153.032L181.878 153.61L181.824 153.525L179.265 148.635C178.77 147.691 178.074 146.865 177.227 146.216L154.625 128.915L150.33 125.626L156.418 118.611Z" fill="#FFCCB3"/>
                <path d="M184.148 143.073C183.151 142.61 182.033 142.196 180.94 142.261C181.598 142.838 181.899 143.865 182.335 144.613C183.231 146.154 185.527 149.018 184.353 151.451C183.694 152.818 181.85 153.545 181.824 153.525C181.515 154.929 179.986 160.645 183.036 157.332C184.739 155.481 186.428 153.505 187.277 151.542C187.626 150.738 186.889 149.936 186.528 149.256C186.065 148.229 185.375 146.868 185.462 145.73L185.48 145.705C185.531 145.626 189.48 149.161 189.547 149.178C189.683 148.958 190 148.514 190 148.514C190 148.514 184.337 143.143 184.148 143.073Z" fill="#EFA12C"/>
                <path d="M141.619 161.769C141.584 161.741 141.552 161.745 141.556 161.776C141.524 161.78 141.524 161.78 141.496 161.816C141.212 161.971 140.942 162.151 140.69 162.354L141.278 167.258L141.918 167.213C141.918 167.213 142.131 164.963 142.107 164.225C142.101 163.903 142.098 163.613 142.096 163.323C142.097 163.065 142.13 162.803 142.135 162.577C142.189 162.216 141.662 161.86 141.69 161.825C141.687 161.793 141.619 161.769 141.619 161.769Z" fill="#233B62"/>
                <path d="M139.612 122.197L139.749 127.371L140.571 156.254L140.6 156.766L140.539 157.063L140.543 157.095L139.528 162.343L139.406 162.938L139.365 163.136L138.665 166.702L136.921 166.137L136.417 165.972L135.712 165.734L134.574 165.387L134.543 165.391L133.337 165.02L133.358 164.92L134.948 159.636C135.254 158.614 135.334 157.537 135.182 156.481L131.126 128.309L130.354 122.953L139.612 122.197Z" fill="#FFCCB3"/>
                <path d="M142.327 159.075C141.939 158.048 141.429 156.97 140.602 156.252C140.67 157.125 140.166 158.07 139.954 158.909C139.517 160.637 139.156 164.29 136.616 165.209C135.188 165.725 133.361 164.954 133.357 164.922C132.155 165.71 127.064 168.727 131.561 168.491C134.073 168.359 136.663 168.128 138.642 167.319C139.453 166.988 139.488 165.899 139.704 165.161C140.093 164.102 140.55 162.646 141.408 161.894L141.44 161.89C141.531 161.869 141.881 167.157 141.918 167.216C142.168 167.154 142.704 167.057 142.704 167.057C142.704 167.057 142.414 159.258 142.327 159.075Z" fill="#EFA12C"/>
                <path d="M134.013 83.3809L126.665 114.408L126.652 116.611L132.626 153.351L142.139 152.321L139.38 117.233L146.948 98.9248L145.418 83.9114L134.013 83.3809Z" fill="#1F2D49"/>
                <path d="M132.984 121.943C136.532 121.943 139.409 119.067 139.409 115.519C139.409 111.97 136.532 109.094 132.984 109.094C129.435 109.094 126.559 111.97 126.559 115.519C126.559 119.067 129.435 121.943 132.984 121.943Z" fill="#1F2D49"/>
                <path d="M141.791 111.398L139.979 96.0684L139.379 117.231L141.791 111.398Z" fill="#0D1B33"/>
                <path d="M153.846 83.2832C153.846 83.2832 157.271 89.0384 157.316 93.858C157.385 101.092 154.776 114.062 154.776 114.062L177.554 137.085L172.131 145.216C172.131 145.216 152.695 129.089 144.471 121.429L142.629 117.699L139.979 96.0688L144.03 83.2832H153.846Z" fill="#1F2D49"/>
                <path d="M134.013 83.3809L153.964 83.5859L155.903 87.5983L133.676 84.8047L134.013 83.3809Z" fill="#0D1B33"/>
                <path d="M148.997 123.293C152.545 123.293 155.422 120.417 155.422 116.868C155.422 113.32 152.545 110.443 148.997 110.443C145.448 110.443 142.572 113.32 142.572 116.868C142.572 120.417 145.448 123.293 148.997 123.293Z" fill="#1F2D49"/>
                <path d="M131.683 40.7414C131.683 40.7414 131.951 43.7238 131.164 46.5906C130.377 49.4574 124.419 55.5281 129.703 60.4748C134.987 65.4213 141.451 52.1555 141.451 52.1555L139.427 46.0284L132.43 38.748L131.683 40.7414Z" fill="#1F2D49"/>
                <path d="M147.804 41.4395C147.804 41.4395 149.39 43.4539 153.003 45.1005C156.548 46.7165 160.365 49.3725 159.58 55.1622C158.792 60.9519 148.312 56.4551 145.233 53.1386C142.154 49.8223 142.577 43.901 142.577 43.901L147.804 41.4395Z" fill="#1F2D49"/>
                <path d="M139.699 52.2873C139.699 52.2873 135.677 53.8787 135.063 54.3699C134.449 54.8612 133.866 58.8705 132.822 60.7739C131.778 62.6776 130.251 66.191 132.481 69.4184C134.712 72.6456 134.475 75.2083 134.475 75.2083L134 83.304L153.222 83.6583C153.393 83.6638 153.561 83.633 153.718 83.5684C153.874 83.5038 154.014 83.4066 154.132 83.2833C154.248 83.1603 154.335 83.0137 154.392 82.8537C154.447 82.6937 154.467 82.5236 154.453 82.3551C154.24 79.9946 153.263 76.6799 153.186 72.9214C153.082 67.8277 153.005 50.8079 153.005 50.8079L144.63 50.5449C142.944 50.8359 139.699 52.2873 139.699 52.2873Z" fill="#EFA12C"/>
                <path d="M149.709 57.7656C149.709 57.7656 146.908 60.32 146.743 62.2151C146.578 64.1102 146.197 69.3941 142.597 69.9212C138.184 70.5672 137.029 71.1962 139.074 71.6906C141.12 72.185 145.763 71.384 145.763 73.5033C145.763 75.0116 147.567 76.1399 148.473 75.316C149.38 74.4921 156.599 61.9678 149.709 57.7656Z" fill="#DC8B12"/>
                <path d="M115.922 59.9058L114.403 57.9617C114.085 57.5067 113.662 57.1348 113.171 56.8775C112.423 56.5033 111.277 55.8053 110.879 55.1077C110.219 53.9987 107.619 51.0034 109.875 48.6752C110.71 47.816 110.501 48.0192 112.17 48.045C113.839 48.0706 114.096 49.8027 114.93 50.9364C114.93 50.9364 115.117 49.6287 116.039 49.0311C116.961 48.4336 116.349 52.0831 117.059 53.4662C117.768 54.8492 117.195 55.372 118.875 57.8019L115.922 59.9058Z" fill="#FFCCB3"/>
                <path d="M111.917 48.2441L113.273 51.3215L111.917 48.2441Z" fill="#E56441"/>
                <path d="M111.917 48.2441L113.273 51.3215" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M110.147 48.793L112.027 52.0699L110.147 48.793Z" fill="#E56441"/>
                <path d="M110.147 48.793L112.027 52.0699" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M109.038 50.9473L110.519 52.9035L109.038 50.9473Z" fill="#E56441"/>
                <path d="M109.038 50.9473L110.519 52.9035" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M114.581 51.6328C114.581 51.6328 114.007 53.277 114.106 54.5725L114.581 51.6328Z" fill="#E56441"/>
                <path d="M114.581 51.6328C114.581 51.6328 114.007 53.277 114.106 54.5725" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M136.04 53.8418C134.481 54.3823 132.307 55.4784 131.719 56.7015L126.522 68.5408L117.867 55.3895L114.589 58.8417L122.672 75.5596C124.149 79.2359 127.901 80.6171 130.619 76.655L134.962 69.8345L136.04 53.8418Z" fill="#EFA12C"/>
                <path d="M133.824 64.0327C133.824 64.0327 133.356 63.2244 133.356 67.4437L130.62 76.6543L134.962 69.8338C138.446 65.1502 135.693 63.8993 133.824 64.0327Z" fill="#FF967C"/>
                <path d="M143.352 58.4487L128.996 64.1669C128.55 64.3445 128.333 64.8503 128.51 65.2962L135.696 83.3352C135.873 83.7813 136.379 83.9989 136.825 83.8213L151.181 78.1028C151.627 77.9252 151.844 77.4196 151.667 76.9736L144.481 58.9345C144.304 58.4885 143.798 58.271 143.352 58.4487Z" fill="#4361EE"/>
                <path d="M131.187 65.8619L137.106 63.5039" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M132.081 67.9811L134.779 66.9062" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M139.19 48.0461L139.416 54.3745C139.416 54.3745 139.106 55.7602 140.529 55.6065C141.951 55.4528 143.866 54.3328 144.942 53.1029C146.018 51.8729 145.945 51.7742 145.483 50.967C145.022 50.1599 142.857 44.0078 142.857 44.0078L139.19 48.0461Z" fill="#FFCCB3"/>
                <path d="M139.222 48.5762L141.845 46.9062C141.845 46.9062 140.683 52.0058 139.416 54.3738L139.222 48.5762Z" fill="#FCBA9F"/>
                <path d="M132.151 38.5745C132.151 38.5745 132.11 44.8723 134.827 48.0419C136.599 50.1087 138.463 49.8347 141.572 46.4318C144.423 43.3118 144.823 41.6857 144.823 41.6857L142.854 35.507C142.854 35.507 132.471 32.8428 132.151 38.5745Z" fill="#FFCCB3"/>
                <path d="M134.856 37.1138C134.856 37.1138 135.994 39.8777 138.108 40.65C140.221 41.4223 140.774 42.8586 140.774 42.8586C140.774 42.8586 141.18 40.4955 142.841 40.5814C144.502 40.6673 144.165 43.6001 143.134 44.5738C143.134 44.5738 144.374 46.8744 145.426 48.5758L150.032 44.0043C150.032 44.0043 147.659 41.2607 147.137 39.0532C146.615 36.8456 144.926 30.9357 138.488 31.3271C130.928 31.7866 131.363 37.9294 131.684 41.3411C131.684 41.3411 134.054 40.4321 134.856 37.1138Z" fill="#1F2D49"/>
                <path d="M135.674 46.0948L137.512 45.1934C137.513 45.2037 137.515 45.2149 137.516 45.2265C137.529 45.3229 137.535 45.4555 137.508 45.601C137.457 45.8859 137.281 46.2317 136.759 46.4562C136.424 46.5648 136.138 46.4576 135.928 46.3151C135.823 46.2434 135.739 46.1643 135.682 46.1027C135.679 46.1001 135.677 46.0975 135.674 46.0948Z" fill="#E56441" stroke="#E56441"/>
                <path d="M133.672 43.5373C133.904 43.4799 134.024 43.156 133.94 42.8137C133.855 42.4714 133.598 42.2404 133.366 42.2978C133.134 42.3551 133.014 42.6791 133.098 43.0214C133.183 43.3635 133.44 43.5946 133.672 43.5373Z" fill="#080B09"/>
                <path d="M137.116 42.7385C137.348 42.6812 137.468 42.3572 137.383 42.0148C137.299 41.6727 137.042 41.4417 136.81 41.4989C136.578 41.5564 136.458 41.8802 136.542 42.2226C136.627 42.5649 136.883 42.7959 137.116 42.7385Z" fill="#080B09"/>
                <path d="M132.336 41.6915L134.089 41.2893C134.118 41.2799 134.143 41.2603 134.159 41.2341C134.174 41.2077 134.18 41.1766 134.175 41.1463C134.155 41.095 134.126 41.0479 134.087 41.0081C134.049 40.9685 134.003 40.9368 133.953 40.9153C133.902 40.8938 133.848 40.8828 133.792 40.8828C133.737 40.883 133.683 40.8944 133.632 40.9161L132.258 41.4982C132.144 41.5264 132.193 41.7268 132.336 41.6915Z" fill="#080B09"/>
                <path d="M138.22 40.1256L135.937 40.8409C135.922 40.7696 135.922 40.6959 135.937 40.6247C135.952 40.5534 135.982 40.486 136.024 40.4267C136.067 40.3676 136.121 40.3181 136.184 40.2813C136.247 40.2446 136.317 40.2214 136.389 40.2133L138.213 39.9756C138.27 39.9616 138.305 40.1045 138.22 40.1256Z" fill="#080B09"/>
                <path d="M134.901 42.3945L134.653 44.2423L135.306 44.5574" fill="#E56441"/>
                <path d="M134.901 42.3945L134.653 44.2423L135.306 44.5574" stroke="#E56441" stroke-width="0.5" stroke-linecap="round"/>
                <path d="M136.638 74.793L130.978 76.417L130.286 79.9049L131.574 83.7652C131.574 83.7652 132.479 83.1561 132.29 81.7353C132.102 80.3142 136.885 80.3548 137.761 78.5686" fill="#FFCCB3"/>
                <path d="M149.568 55.5685C149.568 55.5685 149.531 50.774 153.371 50.7734C155.948 50.773 157.18 51.9809 157.509 55.75C157.738 58.3851 157.905 72.8437 157.905 72.8437C157.905 72.8437 158.552 79.4425 152.955 79.5657L136.793 79.0817L136.428 74.566L150.517 73.1616L149.568 55.5685Z" fill="#EFA12C"/>
                <path d="M137.88 54.9676C137.88 54.9676 139.338 54.5528 139.101 53.1212C138.871 51.7124 138.862 50.4893 139.324 50.6596C139.324 50.6596 139.732 51.3591 142.269 50.5817C144.789 49.8333 144.631 48.9688 144.631 48.9688C144.631 48.9688 144.987 48.6529 145.254 49.4638C145.52 50.2747 146.195 52.5202 147.622 52.3586L148.008 56.5441L140.418 59.0425C140.401 59.0715 136.893 55.6733 137.88 54.9676Z" fill="#EFA12C"/>
                <path d="M75.9746 138.494H80.7499" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                <path opacity="0.5" d="M76.9468 140.566H79.3346" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M72.7935 134.354L76.4576 131.798L79.0387 133.517L82.4741 131.029" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M79.833 129.947H83.4874V133.563" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M123.101 17.6728H108.939C108.766 17.6795 108.593 17.6456 108.436 17.5738C108.278 17.5021 108.139 17.3943 108.031 17.2595C107.922 17.1245 107.846 16.9661 107.81 16.7966C107.773 16.6272 107.777 16.4518 107.82 16.2839L109.997 5.09278C110.081 4.71034 110.289 4.36653 110.589 4.11519C110.889 3.86388 111.264 3.71924 111.656 3.70395H125.818C125.991 3.69727 126.163 3.73118 126.321 3.80295C126.479 3.87472 126.618 3.98237 126.726 4.11731C126.835 4.25224 126.911 4.41073 126.947 4.5801C126.984 4.74947 126.98 4.92503 126.937 5.09278L124.76 16.2839C124.676 16.6664 124.468 17.0101 124.168 17.2615C123.868 17.5129 123.493 17.6574 123.101 17.6728Z" fill="#4361EE"/>
                <path d="M113.387 6.72461L122.817 6.72644C122.885 6.72349 122.952 6.73624 123.014 6.76369C123.076 6.79116 123.131 6.83256 123.174 6.88458C123.217 6.93676 123.248 6.99828 123.263 7.06406C123.279 7.13004 123.279 7.19847 123.263 7.26445L123.134 7.9704C123.113 8.07293 123.068 8.16917 123.003 8.25139C122.938 8.33383 122.854 8.40002 122.76 8.44468L117.593 10.9312C117.513 10.9735 117.424 10.9954 117.334 10.9948C117.243 10.9944 117.154 10.9714 117.075 10.9282L112.825 8.36631C112.747 8.31779 112.687 8.24632 112.651 8.16186C112.616 8.07719 112.608 7.984 112.629 7.89467L112.744 7.26242C112.776 7.1138 112.856 6.98 112.973 6.88255C113.089 6.78499 113.235 6.7294 113.387 6.72461Z" fill="white"/>
                <path d="M112.919 9.29795L116.985 11.8485C117.04 11.8795 117.102 11.896 117.166 11.896C117.229 11.896 117.291 11.8795 117.346 11.8485L122.345 9.29978C122.614 9.16273 122.885 9.33653 122.834 9.61367L122.091 13.6766C122.034 13.9435 121.89 14.1839 121.68 14.3592C121.471 14.5344 121.209 14.6343 120.936 14.6428L112.373 14.6412C112.252 14.6464 112.131 14.6235 112.02 14.5742C111.908 14.5248 111.81 14.4505 111.733 14.3569C111.655 14.2631 111.6 14.1529 111.572 14.0345C111.544 13.9161 111.544 13.7929 111.572 13.6745L112.315 9.61164C112.327 9.54708 112.353 9.48596 112.392 9.43297C112.431 9.37998 112.481 9.33673 112.54 9.30648C112.598 9.27623 112.663 9.25958 112.728 9.25816C112.794 9.25654 112.859 9.27014 112.919 9.29795Z" fill="white"/>
                <path d="M126.308 6.63182C127.926 6.63182 129.239 5.31949 129.239 3.70067C129.239 2.08185 127.926 0.769531 126.308 0.769531C124.689 0.769531 123.376 2.08185 123.376 3.70067C123.376 5.31949 124.689 6.63182 126.308 6.63182Z" fill="#FF4848"/>
                <path d="M126.513 68.5348L128.943 72.1457L127.647 65.9766L126.513 68.5348Z" fill="#DC8B12"/>
                <defs>
                  <linearGradient id="paint0_linear_341_1819" x1="84.7491" y1="35.8792" x2="88.457" y2="98.8285" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="#94B9FF"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_341_1819" x1="75.2583" y1="54.4742" x2="90.7761" y2="163.25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="#4361EE"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_341_1819" x1="115.424" y1="54.4742" x2="130.942" y2="163.25" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="#4361EE"/>
                  </linearGradient>
                  <linearGradient id="paint3_linear_341_1819" x1="38.326" y1="115.462" x2="43.8302" y2="169.024" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white"/>
                    <stop offset="1" stop-color="#4361EE"/>
                  </linearGradient>
                </defs>
              </svg>


            </b-col>

          </b-row>


          <div class="">
            <div class="title">
              You are doing great 🎉<br>
              <div
                  style="font-family: Mulish;font-style: normal;font-weight: normal;font-size: 14px;line-height: 120%;">
                You can personalise your dashboard by clicking on the settings button above.
              </div>
            </div>

          </div>


          <div class="align-self-end ml-auto mt-md-0 mb-3 mr-3 ">
            <b-button class="btn-block" variant="primary" @click="goToRota()">
              <span class="align-middle mb-1">Go to rota</span>
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row> -->
   
    <b-row class="match-height mt-5">
      <b-col cols="12" v-if="this.leaves_rolling === 1">
        <div class="d-flex flex-row pt-1">
          <div class="items" style="padding-top:8px">
              Leaves
          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(leaves_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
          <Leaves />
        </div>
      </b-col>
    </b-row>
    <b-row class="match-height mt-5">
      <b-col
          v-if=" this.shift_applications === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Shift applications


          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
<!--              <b-dropdown-item>-->
<!--                <b-button variant="outline-white" class="p-0" @click="">-->
<!--                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>-->
<!--                  <span>View all</span>-->
<!--                </b-button>-->
<!--              </b-dropdown-item>-->
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(shift_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">

<!--          <b-card no-body class=" px-0 mx-0" style="height: 500px;">-->
          <ShiftApplications />
<!--          </b-card>-->

        </div>
      </b-col>


      <!-- <b-col
          v-if=" this.leaves_rolling === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Leave requests

          </div>
          <div class="ml-auto pr-0 mr-0">

            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="goToLeaveRequests()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(leaves_rolling_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
            <LeaveRequests />
        </div>
      </b-col> -->

      <b-col
          v-if=" this.connection_requests === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Connection requests


          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="goToConnectionRequests()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(connection_requests_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
<!--          <b-card no-body style="margin-bottom: 5px; height: 500px;">-->
            <ConnectionRequests />
<!--          </b-card>-->
        </div>

      </b-col>

      <b-col
          v-if=" this.time_sheets === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
             Timesheets


          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="goToTimeSheet()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(time_sheet_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">

<!--          <b-card no-body class=" px-0 mx-0" style="height: 500px;">-->
            <TimeSheets />
<!--          </b-card>-->

        </div>
      </b-col>


      <b-col
          v-if=" this.locum_requests === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Locum requests

          </div>
          <div class="ml-auto pr-0 mr-0">

            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="goToLocumRequests()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(locum_request_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
<!--          <b-card no-body class="px-0 mx-0" style="height: 500px;">-->
            <LocumRequests />
<!--          </b-card>-->
        </div>
      </b-col>

      <!-- <b-col
          v-if=" this.locum_invoices === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Locum invoices


          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="gotoLocumInvoices()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(locum_invoice_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
            <LocumInvoices />
        </div>

      </b-col> -->

            <b-col
          v-if=" this.swap_shift === 1"
          lg="4"
          md="6"

      >
        <div class="d-flex flex-row pt-1">

          <div class="items" style="padding-top:8px">
              Swap shifts


          </div>
          <div class="ml-auto">
            <b-dropdown
                dropleft text="Drop-Left"
                variant="link"
                class="mr-0"

                toggle-class="text-decoration-none"
                no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    style="color: #B2B2B2"
                    size="18"
                />
              </template>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="gotoLocumInvoices()">
                  <feather-icon icon="ExternalLinkIcon" class="mr-50"/>
                  <span>View all</span>
                </b-button>
              </b-dropdown-item>
              <b-dropdown-item>
                <b-button variant="outline-white" class="p-0" @click="updateDashboard(swap_shift_value)">
                  <feather-icon icon="EyeOffIcon" class="mr-50"/>
                  <span>Hide from dashboard</span>
                </b-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </div>
        <div class="mt-1">
<!--          <b-card no-body style="margin-bottom: 5px; height: 500px;">-->
            <SwapShift />
<!--          </b-card>-->
        </div>

      </b-col>
    </b-row>

    <DashboardSettingsSidebar
        ref="AddLeaveSidebar"
        :is-dashboard-settings-sidebar-active.sync="isDashboardSettingsSidebarActive"
        :shift-applications="shift_applications"
        :connection-requests="connection_requests"
        :time-sheets="time_sheets"
        :leave-requests="leaves_rolling"
        :locum-requests="locum_requests"
        :locum-invoices="locum_invoices"
        :leaves_rolling="leaves_rolling"
        @updateOfDashboard="showDashboardItems"
    />
    </b-overlay>

  </div>
</template>

<script>
import {
  BAlert,
  BCardText,
  BLink,
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BSidebar,
  BTab,
  BTable,
  BTabs,
  VBTooltip, VBToggle, VBModal
} from 'bootstrap-vue'

import profileAPI from '@/apis/modules/profile'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'
import ShiftApplications from './views/shiftApplications'
import ConnectionRequests from './views/connectionRequests'
import dashboard from '@/apis/modules/dashboard'
import DashboardSettingsSidebar from './sidebars/dashboardSetings'
import TimeSheets from './views/timeSheets'
import LocumRequests from './views/locumRequests'
import LocumInvoices from '@/views/dashboard/views/locumInvoices'
import Leaves from '@/views/dashboard/views/leaves.vue'
import SwapShift from '@/views/dashboard/views/swapShifts.vue'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
// import TimeSheets from './views/timeSheets'

export default {
  components: {
    Leaves,
    BCardText,
    BLink,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BCard,
    BTabs,
    BTab,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
    VuePerfectScrollbar,
    ConnectionRequests,
    ShiftApplications,
    DashboardSettingsSidebar,
    TimeSheets,
    LocumRequests,
    LocumInvoices,
    SwapShift
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip
  },
  mixins: [MomentMixin],

  data () {
    return {
      leaveRolling:0,
      isDashboardSettingsSidebarActive: false,
      tableLoading: false,
      user: '',
      details: {},
      summery: {},
      shiftApplications: [],
      connectionRequests: [],
      fields: [
        {
          key: 'name',
          label: ''
        },

        {
          key: 'action',
          label: ''
        }
      ],
      noDataTable: 0,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      shift_applications: '',
      connection_requests: '',
      time_sheets: '',
      leave_requests: '',
      locum_requests: '',
      locum_invoices: '',
      leaves_rolling: '',
      swap_shift:'',
      shift_value: 1,
      connection_requests_value: 2,
      time_sheet_value: 3,
      leave_request_value: 4,
      locum_request_value: 5,
      locum_invoice_value: 6,
      leaves_rolling_value: 7,
      swap_shift_value:8
    }
  },

  methods: {
    toggleSideBars () {
      this.isDashboardSettingsSidebarActive = !this.isDashboardSettingsSidebarActive
    },

    refresh(){
      location.reload();
    },

    showDashboardItems (val) {
      this.shift_applications = val.shift_applications
      this.connection_requests = val.connection_requests
      this.time_sheets = val.time_sheets
      this.leave_requests = val.leave_requests
      this.locum_requests = val.locum_requests
      this.locum_invoices = val.locum_invoices
      this.leaves_rolling = val.leaves
      this.swap_shift = val.swap_shift
    },

    async getAuthUser () {
      try {
        this.tableLoading = true
        const response = await profileAPI.getCurrentUser()
        this.user = response.data.data
        this.tableLoading = false
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getSummery () {
      try {
        const response = await dashboard.getSummery()
        this.summery = response.data
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getLeaves(){
        try{
            const Response = await AdminLeaveAPI.summary()
            this.todaysLeaveCount = Response.data.data.todays_leave_count
            this.leaveRolling = Response.data.data.leave_rolling
        }catch(error){
            this.convertAndNotifyError(error)
        }
    },

    async updateDashboard (value) {
      try {
        const item_value = value
        let payload = {
          shift_applications: item_value === 1 ? false : this.shift_applications,
          connection_requests: item_value === 2 ? false : this.connection_requests,
          time_sheets: item_value === 3 ? false : this.time_sheets,
          leave_requests: item_value === 4 ? false : this.leave_requests,
          locum_requests: item_value === 5 ? false : this.locum_requests,
          locum_invoices: item_value === 6 ? false : this.locum_invoices,
          leaves_rolling: item_value === 7 ? false : this.leaves_rolling,
          swap_shift_value: item_value === 8 ? false : this.swap_shift
        }
        const response = await dashboard.updateDashboard(payload)
        await this.getDetailsOfDashboard()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getDetailsOfDashboard () {
      try {
        const response = await dashboard.getUpdateDashboardDetails()
        this.shift_applications = response.data[0].shift_applications
        this.connection_requests = response.data[0].connection_requests
        this.time_sheets = response.data[0].time_sheets
        this.leave_requests = response.data[0].leaves_rolling
        this.locum_requests = response.data[0].locum_requests
        this.locum_invoices = response.data[0].locum_invoices
        this.leaves_rolling = response.data[0].leaves
        this.swap_shift = response.data[0].swap_shift
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },



    goToRota(){
      this.$router.push(`/scheduler`)
    },

    goToConnectionRequests() {
      this.$router.push(`/medicalpractice-connection-requests`)
    },

    goToTimeSheet () {
      this.$router.push(`/timesheet`)
    },

    goToLocumRequests () {
      this.$router.push(`/staff-bank-locam-request`)
    },

    gotoLocumInvoices() {
      this.$router.push(`/my-shifts`)
    }

  },

  mounted () {
    this.getAuthUser()
    this.getSummery()
    this.getLeaves()
  }
}
</script>

<style>
.heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 120%;

  color: #171822;
}

.overview {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: #171822;
}

.box {
  height: 113px;
  top: 168px;
  margin-right: 0px;
  background: #CCE6F4;
  /*opacity: 0.5;*/
  border-radius: 4px;
}

.title {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 120%;
}

.summery {
  font-family: Mulish;
  font-style: normal;
  font-size: 18px;
}

.summery-heading {
  font-family: Mulish;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #949494;
}

.items {
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  color: #171822;
}

</style>